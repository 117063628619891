.PraiseSection {
    font-family: 'PT Sans', sans-serif;
    font-size: 1.125rem;
    position: relative;
    overflow-x: hidden;
}

.sliderContainer {
    max-width: 1024px;
    margin: 0 auto;
    min-height: 250px;
}

.headerContainer {
    margin: 115px 0 48px;
}

@media (max-width: 1075px) {
    .headerContainer {
        margin: 2rem 0;
    }
}

@media (max-width: 750px) {
    .sliderContainer {
        height: 620px;
    }
}
