.NavBar {
    display: flex;
    flex-flow: row nowrap;
    padding: 1rem 1.5rem;
    justify-content: space-between;
    color: #2D3139;
}

.name {
    font-family: 'PT Sans Narrow', sans-serif;
    margin: 0;
    padding: 0;
}

.navLinks {
    list-style-type: none;
    display: flex;
    gap: 1.5rem;
    font-family: 'PT Sans', sans-serif;
    margin: 0;
    padding: 0;
    align-items: center;
}

.navLink {
    height: 28px;
}

.navLink a {
    text-decoration: none;
    color: #2D3139;
    padding-bottom: 6px;
}

.navLink a:visited {
    color: #2D3139;
}

.active {
    border-bottom: 2px solid #9A292E;
}

@media (max-width: 750px) {
    .NavBar {
        flex-flow: column nowrap;
        gap: 18px;
        text-align: center;
    }

    .navLinks {
        justify-content: center;
    }
}
