.BuyBookSection {
    height: 863px;
}

.background {
    background: #414651;
    height: 700px;
}

.container {
    margin: 0 auto;
    display: flex;
    max-width: 1024px;
    padding-top: 28px;
}

.bookCover {
    height: 835px;
}

.textContainer {
    display: flex;
    flex-flow: column nowrap;
    max-width: 385px;
    margin: 156px 0 0 98px;
}

.bookTitle {
    font-size: 64px;
    line-height: 83px;
    color: #FDFDFD;
    font-family: 'PT Sans Narrow', sans-serif;
    margin: 0;
}

.nowAvailable {
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: bold;
    font-size: 48px;
    line-height: 62px;
    color: #FDFDFD;
    margin: 14px 0 94px;
}

.mobileOrderButton {
    display: none;
}

@media (max-width: 1075px) {
    .BuyBookSection {
        height: unset;
    }

    .background {
        height: unset;
    }

    .bookCover {
        height: 500px;
    }

    .container {
        max-width: unset;
        margin: 0 auto;
        justify-content: center;
        gap: 2rem;
        padding: 1rem;
    }

    .textContainer {
        margin: 0;
    }
}

@media (max-width: 750px) {
    .container {
        flex-flow: column nowrap;
        align-items: flex-start;
        padding: 0;
        gap: 0;
    }

    .textContainer {
        display: none;
    }

    .bookCover {
        margin: 0 auto 1rem;
        width: 100%;
        height: auto;
    }

    .textContainer {
        margin: 0;
    }

    .mobileOrderButton {
        display: inline-flex;
        margin: 0 auto 1rem;
    }
}
