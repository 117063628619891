.ContactSection {
    position: relative;
    overflow-x: hidden;
    padding: 24px 0;
}

.contactHeaderContainer {
    margin: 24px 0 48px;
}

.socials {
    max-width: 1024px;
    margin: 0 auto 24px;
    display: flex;
    gap: 1rem;
}

@media (max-width: 1075px) {
    .ContactSection {
        margin: 0;
    }

    .socials {
        margin: 0 2rem 2rem;
        justify-content: flex-end;
    }
}
