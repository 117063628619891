.AuthorInterviewsSection {
    background: #D3D4D6;
    position: relative;
    overflow-x: hidden;
    padding: 24px 0;
}

.interviewsHeaderContainer {
    margin: 0 0 48px;
}

.interviews {
    font-family: PT Sans, sans-serif;
    font-size: 1.125rem;
    color: #2D3139;
    max-width: 1024px;
    padding: 0 32px;
    margin: 0 auto;
}

.podcasts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    flex-flow: row wrap;
    gap: 24px;
}

.videoContainer {
    overflow: hidden;
    position: relative;
    width:100%;
}

.videoContainer::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.podcastWrapper {
    border-radius: 16px;
    overflow: hidden;
    width: 300px;
    height: 175px;
    background: #FFFFFF;
    margin: 0 auto;
}

.cprLogo {
    margin: 0 12px;
}

.cprLink, .cprLink:visited {
    color: #9A292E;
    margin: 0 12px;
}

.cprPlayer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    height: 100%;
}

.cprAudio {
    margin: 0 auto;
}

.interviewsListItem {
    line-height: 1.8;
}

@media (max-width: 1075px) {
    .AboutSection {
        margin: 0;
    }

    .aboutText {
        padding: 0 2rem 2rem;
    }
}

@media (max-width: 364px) {
    .interviews {
        padding: 0;
    }
}
