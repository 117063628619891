.AboutTheBookSection {
    min-height: 863px;
}

.aboutTheBookSectionBackground {
    background: #414651;
    height: 700px;
}

.aboutTheBookSectionContainer {
    margin: 0 auto;
    display: flex;
    max-width: 1262px;
    padding: 1rem;
}

.aboutTheBookSectionCover {
    height: 835px;
}

.aboutTheBookSectionTextContainer {
    display: flex;
    flex-flow: column nowrap;
    max-width: 675px;
    margin: 0 0 0 46px;
    max-height: 700px;
}

.aboutTheBookTitleHeader {
    font-family: PT Sans Narrow;
    font-size: 2.25rem;
    color: #FDFDFD;
    margin: 0;
}

.bookDetails {
    font-family: PT Sans;
    font-size: 1rem;
    color: #FDFDFD;
}

.buttonContainer {
    margin: 0 auto;
}

.aboutTheBookSectionCoverMobile,
.aboutTheBookMobileHeader {
    display: none;
}

@media (max-width: 1300px) {
    .aboutTheBookSectionBackground {
        height: 100%;
    }

    .aboutTheBookSectionTextContainer {
        max-height: unset;
        width: 100%
    }

    .aboutTheBookSectionCover {
        height: 500px;
    }

    .aboutTheBookSectionContainer {
        max-width: unset;
        height: 100%;
    }

    .aboutTheBookSectionCoverMobile,
    .aboutTheBookMobileHeader {
        display: none;
    }
}

@media (max-width: 750px) {
    .aboutTheBookSectionBackground {
        background: #FDFDFD;
    }

    .aboutTheBookTitleHeader {
        display: none;
    }

    .aboutTheBookSectionContainer {
        flex-flow: column nowrap;
        align-items: flex-start;
        padding: 0;
    }

    .aboutTheBookSectionCoverMobile {
        margin: 0;
        width: 100%;
        height: auto;
        display: inline-block;
    }

    .aboutTheBookSectionCover {
        display: none;
    }

    .aboutTheBookSectionTextContainer {
        margin: 0 auto;
        min-width: 100%;
    }

    .bookDetails {
        color: #2D3139;
        margin: .5rem 1.5rem;
    }

    .aboutTheBookMobileHeader {
        display: inline-flex;
        margin: 1rem 0 1rem;
    }

    .buttonContainer {
        margin: 1.125rem auto;
    }
}
