.AboutSection {
    background: #414651;
    position: relative;
    overflow-x: hidden;
    margin: 115px 0 0;
}

.aboutHeaderContainer {
    margin: 24px 0 48px;
}

.aboutText {
    font-family: PT Sans, sans-serif;
    font-size: 1.125rem;
    color: #FDFDFD;
    max-width: 1024px;
    margin: 0 auto 24px;
}

@media (max-width: 1075px) {
    .AboutSection {
        margin: 0;
    }

    .aboutText {
        padding: 0 2rem 2rem;
    }
}
