.SimpleSlider {
    width: 100%;
    height: 100%;
    color: #2D3139;
}

.swiper-button-prev,
.swiper-button-next {
    color: #9A292E;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #C4C5C8BF;
    max-width: 1024px;
    min-height: 250px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quote {
    margin: 0 0 18px;
}

@media (max-width: 750px) {
    .swiper-slide {
        height: 620px;
        width: 100%;
        font-size: 1rem;
    }
}
