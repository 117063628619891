.SectionHeader {
    font-family: PT Sans Narrow, sans-serif;
    max-width: 1024px;
    margin: 0 auto;
}

.headerText {
    margin: 0 0 1rem;
    font-size: 2rem;
}

.whiteFont {
    color: #FDFDFD;
}

.darkFont {
    color: #2D3139;
}

.lineSVG {
    margin: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
}


@media (max-width: 1075px) {
    .lineSVG {
        display: none;
    }

    .SectionHeader {
        width: 50%;
        max-width: unset;
        border-bottom: 8px solid #9A292E;
        text-align: right;
        margin: 0 0 0 auto;
        padding-right: 1rem;
    }
}

@media (max-width: 750px) {
    .headerText {
        font-size: 24px;
    }
}
