.OrderButton {
    width: 267px;
    height: 59px;
    background: #9A292E;
    border-radius: 8px;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0;
    font-family: 'PT Sans', sans-serif;
    font-size: 24px;
    line-height: 31px;
    display: flex;
    color: #FDFDFD;
    align-items: center;
    justify-content: center;
}

.OrderButton:visited {
    color: #FDFDFD;
}
